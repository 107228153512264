import {useEffect, useState} from 'react';
import {Linking} from 'react-native';
import {getShareInfo, updateInviteCode} from './share.service';
import globalStore from '@/services/global.state';
import {copyText, errorLog, goTo, inApp, openShare} from '@/utils';
import i18n from '@/i18n';
import {ShareInfo} from '@/components/business/share-panel/share-panel-provider';

const makeShareMessage = (msg: string) =>
  msg
    .replace(/http(.*)$/g, '')
    .concat(location.origin)
    .concat('/invitation-apply');

export function shareToPlatform(
  platform: string,
  link: string,
  tip: string = '',
) {
  if (!globalStore.token) {
    goTo('Login');
    return;
  }
  copyText(link);
  switch (platform) {
    case 'link':
    case 'CopyLink':
      globalStore.globalSucessTotal(i18n.t('share.copy-success'), tip);
      break;
    case 'Facebook':
      Linking.openURL('fb://messaging').catch(e => errorLog(e));
      break;
    case 'Telegram':
      Linking.openURL(`tg://msg_url?url=${encodeURIComponent(link)}`).catch(e =>
        errorLog(e),
      );
      break;
    case 'Whatsapp':
      Linking.openURL('https://wa.me?text=' + encodeURIComponent(link)).catch(
        e => errorLog(e),
      );
      break;
    case 'Instagram':
      Linking.openURL('instagram://media').catch(e => errorLog(e));
      break;
  }
}

export function useShare(autoInit = false) {
  const [code, setCode] = useState<string>('');
  const [invateText, setInviteText] = useState('');

  const copyCode = () => {
    if (!globalStore.token) {
      goTo('Login');
      return;
    }
    copyText(code);
    globalStore.globalSucessTotal(i18n.t('share.copy-success'));
  };

  /**
   * 进行分享
   * @param info
   *
   */
  const doShare = (info: ShareInfo = {}) => {
    const shareText = `${makeShareMessage(invateText)}?code=${code}`;
    copyText(shareText);
    if (inApp) {
      openShare(shareText);
      return;
    }
    info.info = shareText;
    globalStore.shareProviderSubject.next(info);
  };

  const initShare = () => {
    return getShareInfo().then(data => {
      setCode(data.inviteCode);
      setInviteText(data.inviteRegisterLinkUrl);
    });
  };

  const refreshCode = async () => {
    await updateInviteCode();
    await initShare();
  };

  useEffect(() => {
    if (autoInit) {
      initShare();
    }
  }, [autoInit]);

  return {
    initShare,
    doShare,
    code,
    refreshCode,
    copyCode,
  };
}
